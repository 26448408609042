.item {
  display: flex;
  //   margin: 1rem 0;
  border: 1px solid #ccc;
  //   padding: 0.5rem;
  width: 100%;
  margin-right: 0.15rem;

  &--done {
    & > td > img {
      opacity: 0.25;
    }
  }

  &--done > td > &--reset {
    opacity: 1;
  }
}
