.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  //   min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  //   align-items: center;
  justify-content: center;
  color: white;
  overflow-y: scroll;
  overflow-x: hidden;
}
