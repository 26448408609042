html,
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  overflow-y: scroll;
  height: 100%;
  background-color: #282c34;
}

#root {
  height: 100%;
  //   overflow-y: scroll;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
  box-sizing: border-box;
}

.icon-md {
  width: 2rem;
  //   display: block;
  margin-right: 1rem;
}

.z100 {
  position: relative;
  z-index: 999;
}

input {
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  outline: none;
  background-color: transparent;
  color: white;
  //   margin: 0 1rem;
}

select {
  background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
    no-repeat;
  //   background-color: white;
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  outline: none;
  color: white;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
}

table {
  text-align: left;

  td {
    padding: 0.5rem 1rem 0.5rem 0;
  }
  td > img {
    vertical-align: middle;
  }
}
